<template>
    <component :is="'script'" async type="application/javascript" src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9237073401512126"
     crossorigin="anonymous" />
    <!-- Test Square -->
    <ins class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-pub-9237073401512126"
        data-ad-slot="5582437420"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    <component :is="'script'" type="application/javascript">
        (adsbygoogle = window.adsbygoogle || []).push({});
    </component>
</template>