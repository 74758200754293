<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> | 
    <router-link to="/ads">Ads</router-link>
  </nav>
  <button @click="clear">Clear Data</button>
  <router-view/>
</template>
<script setup lang="ts">

function clear(){
  localStorage.clear();
  sessionStorage.clear();
  document.cookie = "";
  document.location.reload();
}

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
