<template>
  <div class="home">
    <h2>URL Scheme</h2>
    <ul>
      <li>
        <a href="branch://open">branch://open</a>
      </li>
    </ul>
    <h2>App Links</h2>
    <ul>
      <li v-for="o, i in links" :key="i">
        <a :href="o">{{o}}</a>
      </li>
    </ul>    
    <h2>Debug</h2>
    <ul>
      <li v-for="o, i in links" :key="i">
        <a :href="`${o}?debug=1`">{{o}}?debug=1</a>
      </li>
    </ul>
    <h2>ASA</h2>
    <ul>
      <li>
        <a href="https://azq8y-alternate.app.link/apple-app-site-association">https://azq8y-alternate.app.link/apple-app-site-association</a>
      </li>
      <li>
        <a href="https://azq8y.app.link/apple-app-site-association">https://azq8y.app.link/apple-app-site-association</a>
      </li>
      <li>
        <a href="https://links.cue.do/apple-app-site-association">https://links.cue.do/apple-app-site-association</a>
      </li>

    </ul>       
  </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';

const links = reactive([
  "https://links.cue.do/frigon",
  "https://azq8y.app.link/frigon",
  "https://azq8y-alternate.app.link/frigon",
]);

</script>